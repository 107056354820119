import { request } from '@umijs/max';
import { ApiResponse } from "@/types/stupidai.api";
import { AuthUtil } from "@/utils/auth";

export class HttpClient {
    public static baseurl = 'https://stupidaiapi.wilsoncyber.net';
    // public static baseurl = 'http://localhost:9000';
    // public readonly baseurl = 'http://192.168.1.111:9000';
    async doRequest<R>(path: string, method: 'GET' | 'POST', payload: any, headers?: { [key: string]: string }) {
        const payloadOption = method === 'GET' ? { params: payload } : { data: payload };
        const res = await request<R>(`${HttpClient.baseurl}${path}`, {
            ...payloadOption,
            method,
            headers: {
                Authorization: `Bearer ${AuthUtil.getAuthToken()}`,
                ...headers
            },
            dataType: 'json',
        });
        return res;
    }
    async get<R>(path: string, params?: { [key: string]: any }): Promise<R> {
        const res = await this.doRequest<ApiResponse<R>>(path, 'GET', params);
        if (res.success) {
            return res.data as R;
        }
        throw new Error(res.message);
    }
    async post<R>(path: string, body: { [key: string]: any }): Promise<R> {
        const res = await this.doRequest<ApiResponse<R>>(path, 'POST', body);
        if (res.success) {
            return res.data as R;
        }
        throw new Error(res.message);
    }
}

export default new HttpClient();