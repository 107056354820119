import authService from '@/services/auth';
import { useEffect, useState } from "react";
import { AuthUser } from "@/types/stupidai.api";

export default function authHook() {
    const [user, setUser] = useState<AuthUser>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadUser();
    }, []);

    const loadUser = async () => {
        setLoading(true);
        const res = await authService.getUserInfo();
        setUser(res);
        setLoading(false);
    }

    return {
        user,
        loading,
        loadUser,
    };
};