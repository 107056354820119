// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/home","parentId":"ant-design-pro-layout","id":"1"},"2":{"name":"首页","path":"/home","parentId":"ant-design-pro-layout","id":"2"},"3":{"name":"登录","path":"/login","hideInMenu":true,"parentId":"ant-design-pro-layout","id":"3"},"4":{"name":"管理","path":"","parentId":"5","id":"4","originPath":"/admin"},"5":{"path":"/admin","isWrapper":true,"parentId":"ant-design-pro-layout","id":"5"},"6":{"name":"剧本管理","path":"scenario","parentId":"4","id":"6"},"7":{"name":"标签管理","path":"tag","parentId":"4","id":"7"},"8":{"name":"会话管理","path":"session","parentId":"4","id":"8"},"9":{"name":"会员管理","path":"user","parentId":"4","id":"9"},"ant-design-pro-layout":{"id":"ant-design-pro-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import( './EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__Home__index" */'@/pages/Home/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Login__index" */'@/pages/Login/index.tsx')),
'4': React.lazy(() => import( './EmptyRoute')),
'5': React.lazy(() => import(/* webpackChunkName: "wrappers__auth" */'@/wrappers/auth.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__Scenario__index" */'@/pages/Scenario/index.tsx')),
'7': React.lazy(() => import(/* webpackChunkName: "p__Tag__index" */'@/pages/Tag/index.tsx')),
'8': React.lazy(() => import(/* webpackChunkName: "p__Session__index" */'@/pages/Session/index.tsx')),
'9': React.lazy(() => import(/* webpackChunkName: "p__User__index" */'@/pages/User/index.tsx')),
'ant-design-pro-layout': React.lazy(() => import(/* webpackChunkName: "t__plugin-layout__Layout" */'/root/workspace/shanghai-wilson-cyber/stupidaiadmin/src/.umi-production/plugin-layout/Layout.tsx')),
},
  };
}
