import { AuthUser } from '@/types/stupidai.api';
import httpClient from '@/lib/httpclient';

class AuthService {
    async login(username: string, password: string) {
        const res = await httpClient.post<string>('/auth/login', { username, password });
        return res;
    }

    async getUserInfo() {
        const res = await httpClient.get<AuthUser>('/auth/userinfo');
        return res;
    }

    async updateUserPass(username: string, password: string) {
        const res = await httpClient.post<string>('/auth/updateuserpass', { username, password });
        return res;
    }
}
export default new AuthService();
